import { DOCUMENT } from '@angular/common';
import { ApplicationRef, Component, Inject, Optional, PLATFORM_ID, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { Page } from 'src/interfaces/page';
import { SiteSettings } from 'src/interfaces/siteSettings';
import { WindowApp } from 'src/interfaces/windowApp';
import { BrandingService } from './services/branding.service';
import { UtilsPublicService } from './services/utils-public.service';
import * as Sentry from "@sentry/browser";

declare var window: Window & WindowApp;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [BrandingService],
})
export class AppComponent {
    selectedSite?: SiteSettings;

    constructor(@Optional() @Inject('siteSettings') public siteSettings: SiteSettings, @Optional() @Inject('pageContent') public pageContent: Page, @Inject(DOCUMENT) private document: Document, protected router: Router, private Branding: BrandingService, private appRef: ApplicationRef, private UtilsPublic: UtilsPublicService, @Optional() @Inject('javascript') private javascript: { platform: string, sdk: string, jquery: string }, @Optional() @Inject('isAdmin') private isAdmin: boolean) {
        if (UtilsPublic.isBrowser())
            Sentry.setTag('domain', window.location.hostname);

        this.addPlatform();
        if (siteSettings && typeof siteSettings === 'object')
            this.UtilsPublic.loadJSInline(`window.app.siteId = '${siteSettings._id}';`);

        setTimeout(() => {
            if (this.router.url.indexOf('/pagePreview') === 0 && typeof localStorage === 'object') {
                // if doing page preview, load branding like a page from the stored data
                if (localStorage.getItem('pagePreview')) {
                    this.pageContent = JSON.parse(localStorage.getItem('pagePreview') || '');

                    this.setBranding();
                }
            }
        }, 0);


        this.setBranding();

        if (this.UtilsPublic.isBrowser()) {
            window.app.appRef = this.appRef;
            window.app.utils = this.UtilsPublic;
            window.sdk.events.trigger('utils', true);
        }
    }

    async ngOnInit(): Promise<void> {

    }

    addPlatform(): void {
        if (this.UtilsPublic.isBrowser())
            return;
        // SSR ONLY

        if (this.javascript) {
            if (this.javascript.platform) this.UtilsPublic.loadJSInline(this.javascript.platform, 'platform'); else this.UtilsPublic.loadJS('/assets/platform.js');
            if (this.javascript.sdk) this.UtilsPublic.loadJSInline(this.javascript.sdk, 'sdk'); else this.UtilsPublic.loadJS('/assets/sdk.js');
            if (this.javascript.jquery) this.UtilsPublic.loadJSInline(this.javascript.jquery, 'sdk'); else this.UtilsPublic.loadJS('/assets/jquery.min.js');
        } else {
            this.UtilsPublic.loadJS('/assets/platform.js');
            this.UtilsPublic.loadJS('/assets/sdk.js');
            this.UtilsPublic.loadJS('/assets/jquery.min.js');
        }

        if (this.isAdmin)
            this.UtilsPublic.loadJS('/assets/admin.js');
    }

    setBranding(): void {
        let metadata: { title: string, description: string } = { title: '', description: '' };

        if (this.siteSettings) {
            this.Branding.setBranding(this.siteSettings);
            metadata = this.siteSettings.metadata;
        }
        if (this.pageContent) {
            this.Branding.setBranding(this.pageContent);
            metadata = this.pageContent.metadata;
        }

        if (metadata && metadata.title) {
            this.document.title = metadata.title;
            const metaDesc = this.document.head.querySelector('meta[name="description"]') as any;
            metaDesc.content = metadata.description;
        }

    }
}
