import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular";

if (environment.production) {
    enableProdMode();
}

function bootstrap() {
    if (environment.production)
        Sentry.init({
            dsn: "https://1bb695e5c3a6645f59c335749d36d6e7@o4508252426403840.ingest.de.sentry.io/4508256350634064",
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration({ maskAllText: false }),
            ],
            // Tracing
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });

    platformBrowserDynamic()
        .bootstrapModule(AppModule)
        .catch((err) => console.error(err));


    // platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
};


if (document.readyState === 'complete') {
    bootstrap();
} else {
    document.addEventListener('DOMContentLoaded', bootstrap);
}


