<section [formGroup]="formGroup">
    <mat-form-field [appearance]="Validation.getAppearance(question)" [hideRequiredMarker]="Validation.getHideRequired(question)" [floatLabel]="Validation.getFloatLabel(question)"
        class="{{requireConfirmation === false && control.hasError('typo') ? 'has-typo':''}}">
        <mat-label>{{question.label}}</mat-label>
        <input type="email" matInput [placeholder]="Validation.getPlaceholder(question)" [formControlName]="controlName" autocomplete="email" [formControlName]="controlName" (keyup)="hasTypoOverride = false"
            [maxlength]="Validation.getCustomValidationAttribute(question,'maxLength')">

        <mat-error *ngFor="let error of Validation.getCustomValidationErrors(question, control)">
            {{error}}
        </mat-error>

        <mat-hint *ngIf="question.options.branding?.hint">{{question.options.branding?.hint}}</mat-hint>

        <mat-error *ngIf="control && control.hasError('email') && !control.hasError('required')" [innerHTML]="Validation.getCustomErrorMessage(question, 'emailInvalidMessage', 'Please type in a valid email address')">
            &nbsp;
        </mat-error>
        <mat-error *ngIf="control && control.hasError('required')">
            <span [innerHTML]="Validation.getRequiredMessage(question)">&nbsp;</span>
        </mat-error>
        <mat-error *ngIf="!requireConfirmation && control && control.hasError('typo') && control.errors && ObjectKeys(control.errors).length === 1">
            {{question.options.typeSpecificOptions.typoCheckMessage || 'Are you sure this is correct?'}}
            <span class="typo-confirmation" (click)="hasTypoOverride = true; control.updateValueAndValidity()">{{question.options.typeSpecificOptions.typoYesMessage || 'Yes this email address is correct'}}</span>
        </mat-error>

        <mat-hint *ngIf="question.options.typeSpecificOptions && question.options.typeSpecificOptions.typoCheck && !question.options.typeSpecificOptions.typoCheckForceStop && !hasTypoOverride && emailHasTypo(control.value)">
            {{question.options.typeSpecificOptions.typoCheckMessage || 'Are you sure this is correct?'}}
            <span class="typo-confirmation" (click)="hasTypoOverride = true;">{{question.options.typeSpecificOptions.typoYesMessage || 'Yes this email address is correct'}}</span>
        </mat-hint>
    </mat-form-field>
    <br />
    <mat-form-field *ngIf="requireConfirmation" [appearance]="Validation.getAppearance(question)" [hideRequiredMarker]="Validation.getHideRequired(question)" [floatLabel]="Validation.getFloatLabel(question)"
        class="{{confirmControl.hasError('typo') ? 'has-typo':''}}">
        <mat-label class="default">{{question.options.typeSpecificOptions?.confirmLabel || 'Please confirm by typing in the email address again'}}</mat-label>
        <!-- <mat-label class="mobile">Please confirm email</mat-label> -->
        <input type="email" matInput [placeholder]="Validation.getPlaceholder(question)" autocomplete="email" [formControlName]="controlName + '-confirm'" (keyup)="hasTypoOverride = false"
            [maxlength]="Validation.getCustomValidationAttribute(question,'maxLength')">

        <mat-error *ngIf="confirmControl && confirmControl.hasError('email') && !confirmControl.hasError('required')" [innerHTML]="Validation.getCustomErrorMessage(question, 'emailInvalidMessage', 'Please type in a valid email address')">
            &nbsp;
        </mat-error>
        <mat-error *ngIf="control && control.hasError('required')">
            <span [innerHTML]="Validation.getRequiredMessage(question)">&nbsp;</span>
        </mat-error>
        <mat-error *ngIf="confirmControl && confirmControl.hasError('matched')" [innerHTML]="Validation.getCustomErrorMessage(question, 'emailNoMatchMessage', 'Please ensure both email addresses are the same')">
            &nbsp;
        </mat-error>
        <mat-error *ngIf="confirmControl && confirmControl.hasError('typo') && confirmControl.errors && ObjectKeys(confirmControl.errors).length === 1">
            {{question.options.typeSpecificOptions.typoCheckMessage || 'Are you sure this is correct?'}}
            <span class="typo-confirmation" (click)="hasTypoOverride = true;  confirmControl.updateValueAndValidity();">{{question.options.typeSpecificOptions.typoYesMessage || 'Yes this email address is correct'}}</span>
        </mat-error>
    </mat-form-field>
</section>